import { Col, DatePicker, Pagination, Row, Spin } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import historyApi from '../../../http/historyApi'
import { SsccHistoriesResponse } from '../../../models/History'
import SectionLayout from '../../stock/stockscreen/component/SectionLayout'
import { HistoryType } from '../enum/enum'
import {
  MinDayForSearch,
  disabledDateAfter,
  disabledDateBefore,
  genTitle,
} from '../func'
import HistoryLayout from '../layout/HistoryLayout'
import HistoryTable from '../table/HistoryTable'
import { buildLines, getLibelle, getRef } from './func'
import './sscc-history.scss'

enum Source {
  FROM = 'FROM',
  TO = 'TO',
}

function SsccHistory() {
  const { sscc } = useParams()
  const [rangeTimestamp, setRageTimestamp] = useState({
    from: 0,
    to: 0,
  })

  const [pageSize, setPageSize] = useState(20)
  const [pageIndex, setPageIndex] = useState(0)

  const [historyResponse, setHistoryResponse] =
    useState<SsccHistoriesResponse>()

  const [loadingData, setLoadingData] = useState<boolean>(false)

  useEffect(() => {
    if (!sscc) return
    searchData({
      id: sscc,
      limit: pageSize,
      offset: pageIndex,
      from: rangeTimestamp.from,
      to: rangeTimestamp.to,
    })
  }, [sscc])

  const onPickTime = (source: Source, value: dayjs.Dayjs | null) => {
    if (source === Source.FROM)
      setRageTimestamp({
        ...rangeTimestamp,
        from: value?.startOf('day').unix() || 0,
      })
    else
      setRageTimestamp({
        ...rangeTimestamp,
        to: value?.endOf('day').unix() || 0,
      })
  }

  const onDisabledFrom = (date: dayjs.Dayjs) => {
    const min = dayjs(MinDayForSearch).startOf('day')
    const max =
      rangeTimestamp.to === 0
        ? dayjs().endOf('day')
        : dayjs.unix(rangeTimestamp.to)
    return disabledDateBefore(min, date) || disabledDateAfter(max, date)
  }

  const onDisabledTo = (date: dayjs.Dayjs) => {
    const min =
      rangeTimestamp.from === 0
        ? dayjs(MinDayForSearch).startOf('day')
        : dayjs.unix(rangeTimestamp.from)
    const max = dayjs().endOf('day')

    return disabledDateBefore(min, date) || disabledDateAfter(max, date)
  }

  const onSearch = () => {
    if (!sscc) return
    setPageSize(20)
    setPageIndex(0)
    searchData({
      id: sscc,
      limit: 20,
      offset: 0,
      from: rangeTimestamp.from,
      to: rangeTimestamp.to,
    })
  }

  const searchData = (params: {
    id: string
    limit: number
    offset: number
    from?: number
    to?: number
  }) => {
    const { id, offset, limit, from, to } = params
    if (!id) return
    setLoadingData(true)
    historyApi
      .getSsccHistories({ sscc: id, limit, offset, from, to })
      .then((res) => {
        setHistoryResponse(res)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingData(false))
  }

  const onClearSearch = () => {
    setRageTimestamp({ from: 0, to: 0 })
    setPageIndex(0)
    setPageSize(20)
    if (!sscc) return
    searchData({ id: sscc, limit: 20, offset: 0, from: 0, to: 0 })
  }

  const onChangePagination = (page: number, pageSize: number) => {
    setPageIndex(page - 1)
    if (!sscc) return
    searchData({
      id: sscc,
      limit: pageSize,
      offset: page - 1,
      from: rangeTimestamp.from,
      to: rangeTimestamp.to,
    })
  }

  return (
    <HistoryLayout title={genTitle('Historique', sscc || '', HistoryType.SSCC)}>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="bg-[#EEEEEE] px-3 py-3 bd-bot-radius-11">
          <Row align={'middle'} justify={'space-between'}>
            <Col>
              <Row gutter={20} align={'middle'}>
                <Col>
                  <Row align="bottom" gutter={20}>
                    <Col>
                      <DatePicker
                        placeholder="Date début"
                        value={
                          rangeTimestamp.from === 0
                            ? undefined
                            : dayjs.unix(rangeTimestamp.from)
                        }
                        disabledDate={onDisabledFrom}
                        onChange={(value) => onPickTime(Source.FROM, value)}
                        size="small"
                      />
                    </Col>
                    <Col>
                      <DatePicker
                        placeholder="Date fin"
                        value={
                          rangeTimestamp.to === 0
                            ? undefined
                            : dayjs.unix(rangeTimestamp.to)
                        }
                        disabledDate={onDisabledTo}
                        onChange={(value) => onPickTime(Source.TO, value)}
                        size="small"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row align={'top'} gutter={10}>
                    <Col className="flex align-bottom">
                      <div className="icon-search h-6" onClick={onSearch}></div>
                    </Col>
                    <Col className="flex align-bottom">
                      <div
                        className="icon-clear-search h-6"
                        onClick={onClearSearch}
                      ></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <span className="text-[#808080] font-bold text-base">
                Résultats:
              </span>{' '}
              <span className="text-[#808080] font-medium">
                {historyResponse?.data.metadata.total || 0} sur{' '}
                {historyResponse?.data.metadata.count_history || 0}
              </span>
            </Col>
          </Row>
        </div>
        <div className="gap-18"></div>
        <SectionLayout
          title="Identification"
          // pdBot='pb-6'
        >
          <Row gutter={80}>
            <Col>
              <Row gutter={40} align={'middle'}>
                <Col>
                  <span className="color-[#505050] text-base font-semibold">
                    Réf
                  </span>
                </Col>
                <Col>
                  <span className="flex px-2 items-center bg-[#EFEFEF] rounded-xl border-solid border-[#BEBEBE] border-2 text-[#808080] w-min-192 w-max-300 h-min-27">
                    {getRef(historyResponse?.data.entry)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={40} align={'middle'}>
                <Col>
                  <span className="color-[#505050] text-base font-semibold">
                    Libellé
                  </span>
                </Col>
                <Col>
                  <span className="flex px-2 items-center bg-[#EFEFEF] rounded-xl border-solid border-[#BEBEBE] border-2 text-[#808080] w-min-192 w-max-400 h-min-27">
                    {getLibelle(historyResponse?.data.entry)}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </SectionLayout>
        <div className="gap-18"></div>
        <div className="gap-18 bg-[#FBFBFB]"></div>
        <div className="flex-grow overflow-auto bg-[#FBFBFB] px-4 pb-4 flex-1">
          <Spin spinning={loadingData}>
            <HistoryTable
              initData={buildLines(historyResponse?.data.entry || [])}
            />
          </Spin>
        </div>

        <Row className="py-2 px-4 box-border">
          <Col>
            <Pagination
              className="sscc-history-paging"
              showSizeChanger={false}
              total={historyResponse?.data.metadata.total}
              current={pageIndex + 1}
              onChange={onChangePagination}
              pageSize={pageSize}
            />
          </Col>
          <Col></Col>
        </Row>
      </div>
    </HistoryLayout>
  )
}

export default SsccHistory
